import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "linked-accounts"
    }}>{`Linked Accounts`}</h1>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Patrons can start the linking process by selecting 'Linked Accounts' while in the My Account display. Linking an account is great for parents who want to keep track of their child and what item(s) they have on hold, out for checkout or need to be returned. `}</li>
    </ol>
    <p><img alt="My Account Screen" src={require("./images/my_account.png")} />{` `}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Selecting 'Add' will prompt the patron to enter in the Username and Password for the linked account they wish to add. `}</li>
      <li parentName="ol">{`After an account is associated, the patron can review their list of linked accounts. `}</li>
      <li parentName="ol">{`From here, patrons can remove accounts as needed. To remove an account, select 'Remove' and confirm the removal. `}<em parentName="li">{`This action will not delete the account. It only removes the account as being linked.`}</em></li>
    </ol>
    <p><img alt="Linked Account Screen" src={require("./images/linked_accounts.png")} />{` `}<img alt="Linked Account Remove Screen" src={require("./images/remove_linked_account.png")} />{` `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      